import Cookies from 'js-cookie'
import moment from 'moment'
import '@/locale/zh-TW.js'
import '@/locale/en.js'

export default class LocalePreferenceService {
  constructor(vuexStore) {
    this.vuexStore = vuexStore
  }

  get localeConfig() {
    return (
      this.vuexStore.getters['siteConfigs/siteCustomDisplayConfig']
        .locale_preference_config || {}
    )
  }

  get availableLocales() {
    return this.localeConfig.available_locales || []
  }

  setLocale() {
    const locale = Cookies.get('locale') || this.localeConfig.default_locale

    I18n.locale = locale
    moment.locale(I18n.locale)
  }

  updateLocale(selectedLocale) {
    return this.vuexStore
      .dispatch('updateLocale', selectedLocale)
      .then((_) => location.reload())
  }
}
